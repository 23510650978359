<template>
  <q-dialog
    :ref="modalRef"
    v-cloak
    full-width
    position="bottom"
    class="--fullscreen-height__padded"
  >
    <DialogHeader header="Filters" header-icon="sym_r_tune" />

    <q-card
      class="bg-background"
      style="position: relative; width: 100%; height: 100%"
    >
      <q-form
        @submit="filterSearchResults"
        @reset="resetFilters"
        class="auto__srp__filters-mobile"
      >
        <q-list separator class="bg-background">
          <q-expansion-item expand-separator label="Make & Model">
            <q-expansion-item
              v-for="makeOption in searchFilterOptions.makeModelsNested"
              :key="makeOption.value + '_makeSearchFilterOption'"
              :header-inset-level="0"
              dense
              dense-toggle
              expand-separator
            >
              <template v-slot:header>
                <q-item-section>
                  <q-item-label>{{ makeOption.label }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                  <small>{{ makeOption.count }}</small>
                </q-item-section>
              </template>

              <q-item
                :key="makeOption.value + '_all_modelSearchFilterOption'"
                :inset-level="0.3"
                v-ripple
                tag="label"
                dense
                :disable="
                  refreshingFilterOptions.length > 0 &&
                    refreshingFilterOptions != 'makeId'
                "
              >
                <q-item-section side>
                  <q-checkbox
                    dense
                    v-model="searchFilterModels.makeId"
                    :val="makeOption.value"
                    color="secondary"
                    @click="performSearchFilterOptionsRefresh('makeId')"
                    :disable="
                      refreshingFilterOptions.length > 0 &&
                        refreshingFilterOptions != 'makeId'
                    "
                  />
                </q-item-section>
                <q-item-section>
                  All Models
                </q-item-section>
                <q-item-section side>
                  <small>{{ makeOption.count }}</small>
                </q-item-section>
              </q-item>

              <q-item
                v-for="modelOption in makeOption.modelOptions"
                :key="
                  makeOption.value +
                    '_' +
                    modelOption.value +
                    '_modelSearchFilterOption'
                "
                :inset-level="0.3"
                v-ripple
                tag="label"
                dense
                :disable="
                  refreshingFilterOptions.length > 0 &&
                    refreshingFilterOptions != 'modelId'
                "
              >
                <q-item-section side>
                  <q-checkbox
                    dense
                    v-model="searchFilterModels.modelId"
                    :val="modelOption.value"
                    color="secondary"
                    @click="performSearchFilterOptionsRefresh('modelId')"
                    :disable="
                      refreshingFilterOptions.length > 0 &&
                        refreshingFilterOptions != 'modelId'
                    "
                  />
                </q-item-section>
                <q-item-section>
                  <span class="ellipsis full-width">{{
                    modelOption.label
                  }}</span>
                </q-item-section>
                <q-item-section side>
                  <small>{{ modelOption.count }}</small>
                </q-item-section>
              </q-item>
            </q-expansion-item>
          </q-expansion-item>

          <q-expansion-item expand-separator label="Body Type">
            <q-item
              v-for="(option, i) in searchFilterOptions.bodyType"
              :key="i + '_bodyTypeSearchFilterOption'"
              :inset-level="0"
              v-ripple
              tag="label"
              dense
              :disable="
                refreshingFilterOptions.length > 0 &&
                  refreshingFilterOptions != 'bodyType'
              "
            >
              <q-item-section side>
                <q-checkbox
                  dense
                  v-model="searchFilterModels.bodyType"
                  :val="option.value"
                  color="secondary"
                  :disable="
                    refreshingFilterOptions.length > 0 &&
                      refreshingFilterOptions != 'bodyType'
                  "
                  @click="performSearchFilterOptionsRefresh('bodyType')"
                />
              </q-item-section>
              <q-item-section>
                {{ option.label }}
              </q-item-section>
              <q-item-section side>
                <small>{{ option.count }}</small>
              </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item expand-separator label="Condition">
            <q-item
              v-for="(option, i) in searchFilterOptions.condition"
              :key="i + '_conditionSearchFilterOption'"
              :inset-level="0"
              v-ripple
              tag="label"
              dense
              :disable="
                refreshingFilterOptions.length > 0 &&
                  refreshingFilterOptions != 'condition'
              "
            >
              <q-item-section side>
                <q-checkbox
                  dense
                  v-model="searchFilterModels.condition"
                  :val="option.value"
                  color="secondary"
                  :disable="
                    refreshingFilterOptions.length > 0 &&
                      refreshingFilterOptions != 'condition'
                  "
                  @click="performSearchFilterOptionsRefresh('condition')"
                />
              </q-item-section>
              <q-item-section>
                {{ option.label }}
              </q-item-section>
              <q-item-section side>
                <small>{{ option.count }}</small>
              </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item expand-separator label="Price">
            <q-item>
              <div class="row full-width">
                <q-input
                  filled
                  dense
                  v-model.number="searchFilterModels.price.min"
                  label="Min Price"
                  key="minPrice_SearchFilterInput"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  class="col-4"
                  debounce="750"
                  @blur="performSearchFilterOptionsRefresh('price')"
                />
                <div class="col-4" />
                <q-input
                  filled
                  dense
                  v-model.number="searchFilterModels.price.max"
                  label="Max Price"
                  key="maxPrice_SearchFilterInput"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  class="col-4"
                  debounce="750"
                  @blur="performSearchFilterOptionsRefresh('price')"
                />
              </div>
            </q-item>
            <q-item>
              <q-range
                v-model="searchFilterModels.price"
                @change="
                  val => {
                    searchFilterModels.price = val;
                    performSearchFilterOptionsRefresh('price');
                  }
                "
                :min="searchFilterModelDefaults.price.min"
                :max="searchFilterModelDefaults.price.max"
                :step="100"
                color="secondary"
                label
                key="price_SearchFilterRange"
                style="width:94%;margin:0 auto"
              />
            </q-item>
          </q-expansion-item>

          <q-expansion-item expand-separator label="Mileage">
            <q-item>
              <div class="row full-width">
                <q-input
                  filled
                  dense
                  v-model.number="searchFilterModels.mileage.min"
                  label="Min Mileage"
                  key="minMileage_SearchFilterInput"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  class="col-4"
                  debounce="750"
                  @blur="performSearchFilterOptionsRefresh('mileage')"
                />
                <div class="col-4" />
                <q-input
                  filled
                  dense
                  v-model.number="searchFilterModels.mileage.max"
                  label="Max Mileage"
                  key="maxMileage_SearchFilterInput"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  class="col-4"
                  debounce="750"
                  @blur="performSearchFilterOptionsRefresh('mileage')"
                />
              </div>
            </q-item>
            <q-item>
              <q-range
                v-model="searchFilterModels.mileage"
                @change="
                  val => {
                    searchFilterModels.mileage = val;
                    performSearchFilterOptionsRefresh('mileage');
                  }
                "
                :min="searchFilterModelDefaults.mileage.min"
                :max="searchFilterModelDefaults.mileage.max"
                :step="100"
                color="secondary"
                label
                key="mileage_SearchFilterRange"
                style="width:94%;margin:0 auto"
              ></q-range>
            </q-item>
          </q-expansion-item>

          <q-expansion-item expand-separator label="Year">
            <q-item>
              <div class="row full-width">
                <q-input
                  filled
                  dense
                  v-model.number="searchFilterModels.year.min"
                  label="Min Year"
                  key="minYear_SearchFilterInput"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  class="col-4"
                  debounce="750"
                  @blur="performSearchFilterOptionsRefresh('year')"
                />
                <div class="col-4" />
                <q-input
                  filled
                  dense
                  v-model.number="searchFilterModels.year.max"
                  label="Max Year"
                  key="maxYear_SearchFilterInput"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  class="col-4"
                  debounce="750"
                  @blur="performSearchFilterOptionsRefresh('year')"
                />
              </div>
            </q-item>
            <q-item>
              <q-range
                v-model="searchFilterModels.year"
                @change="
                  val => {
                    searchFilterModels.year = val;
                    performSearchFilterOptionsRefresh('year');
                  }
                "
                :min="searchFilterModelDefaults.year.min"
                :max="searchFilterModelDefaults.year.max"
                :step="1"
                color="secondary"
                label
                key="year_SearchFilterRange"
                style="width:94%;margin:0 auto"
              ></q-range>
            </q-item>
          </q-expansion-item>

          <q-expansion-item expand-separator label="Color">
            <q-item
              v-for="(option, i) in searchFilterOptions.exteriorColor"
              :key="i + '_colorSearchFilterOption'"
              :inset-level="0"
              v-ripple
              tag="label"
              dense
              :disable="
                refreshingFilterOptions.length > 0 &&
                  refreshingFilterOptions != 'exteriorColor'
              "
            >
              <q-item-section side>
                <q-checkbox
                  dense
                  v-model="searchFilterModels.exteriorColor"
                  :val="option.value"
                  color="secondary"
                  :disable="
                    refreshingFilterOptions.length > 0 &&
                      refreshingFilterOptions != 'exteriorColor'
                  "
                  @click="performSearchFilterOptionsRefresh('exteriorColor')"
                />
              </q-item-section>
              <q-item-section>
                {{ option.label }}
              </q-item-section>
              <q-item-section side>
                <small>{{ option.count }}</small>
              </q-item-section>
              <q-item-section side>
                <div
                  v-if="option.value === 'Brown'"
                  style="background: #DBC5A4; border-radius: 200px; width: 20px; height: 20px; position: relative"
                >
                  <div
                    style="position: absolute; top: 0; left: 0; width: 50%; height: 100%; background: #914915; border-top-left-radius: 200px; border-bottom-left-radius: 200px"
                  ></div>
                </div>
                <div
                  v-else
                  :style="{ background: option.color }"
                  style="border-radius: 200px; width: 20px; height: 20px"
                />
              </q-item-section>
            </q-item>
          </q-expansion-item>
          <q-expansion-item expand-separator label="Fuel Type">
            <q-item
              v-for="(option, i) in searchFilterOptions.fuelType"
              :key="i + '_fuelTypeSearchFilterOption'"
              :inset-level="0"
              v-ripple
              tag="label"
              dense
              :disable="
                refreshingFilterOptions.length > 0 &&
                  refreshingFilterOptions != 'fuelType'
              "
            >
              <q-item-section side>
                <q-checkbox
                  dense
                  v-model="searchFilterModels.fuelType"
                  :val="option.value"
                  color="secondary"
                  :disable="
                    refreshingFilterOptions.length > 0 &&
                      refreshingFilterOptions != 'fuelType'
                  "
                  @click="performSearchFilterOptionsRefresh('fuelType')"
                />
              </q-item-section>
              <q-item-section>
                {{ option.label }}
              </q-item-section>
              <q-item-section side>
                <small>{{ option.count }}</small>
              </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item expand-separator label="Transmission">
            <q-item
              v-for="(option, i) in searchFilterOptions.transmission"
              :key="i + '_transmissionSearchFilterOption'"
              :inset-level="0"
              v-ripple
              tag="label"
              dense
              :disable="
                refreshingFilterOptions.length > 0 &&
                  refreshingFilterOptions != 'transmission'
              "
            >
              <q-item-section side>
                <q-checkbox
                  dense
                  v-model="searchFilterModels.transmission"
                  :val="option.value"
                  color="secondary"
                  :disable="
                    refreshingFilterOptions.length > 0 &&
                      refreshingFilterOptions != 'transmission'
                  "
                  @click="performSearchFilterOptionsRefresh('transmission')"
                />
              </q-item-section>
              <q-item-section>
                {{ option.label }}
              </q-item-section>
              <q-item-section side>
                <small>{{ option.count }}</small>
              </q-item-section>
            </q-item>
          </q-expansion-item>
          <q-expansion-item expand-separator label="Engine Type">
            <q-item
              v-for="(option, i) in searchFilterOptions.engineType"
              :key="i + '_engineTypeSearchFilterOption'"
              :inset-level="0"
              v-ripple
              tag="label"
              dense
              :disable="
                refreshingFilterOptions.length > 0 &&
                  refreshingFilterOptions != 'engineType'
              "
            >
              <q-item-section side>
                <q-checkbox
                  dense
                  v-model="searchFilterModels.engineType"
                  :val="option.value"
                  color="secondary"
                  :disable="
                    refreshingFilterOptions.length > 0 &&
                      refreshingFilterOptions != 'engineType'
                  "
                  @click="performSearchFilterOptionsRefresh('engineType')"
                />
              </q-item-section>
              <q-item-section>
                {{ option.label }}
              </q-item-section>
              <q-item-section side>
                <small>{{ option.count }}</small>
              </q-item-section>
            </q-item>
          </q-expansion-item>
        </q-list>
      </q-form>

      <q-card-section
        class="bg-background-light q-py-lg --shadow__top"
        style="position: fixed; bottom: 0; left: 0; right: 0; z-index: 3;"
      >
        <q-btn
          color="secondary"
          label="Apply"
          class="full-width"
          :disable="!$store.state.search.loaded"
          :loading="refreshingFilterOptions.length > 0"
          @click="filterSearchResults"
        />
        <q-btn
          flat
          text-color="text-light"
          label="Reset Filters"
          color="text-light"
          class="full-width q-mt-sm"
          @click="resetFilters"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import DialogHeader from "@/components/UI/DialogHeader.vue";

export default {
  name: "FiltersDialog",
  components: { DialogHeader },
  data() {
    return {
      modalRef: "filtersDialog",
      refreshingFilterOptions: "",
      searchFilterModels: JSON.parse(
        JSON.stringify(this.$store.state.search.filter.models)
      )
    };
  },
  computed: {
    searchFilterOptions() {
      return this.$store.state.search.filter.options;
    },
    searchFilterModelDefaults() {
      return this.$store.state.search.filter.modelDefaults;
    }
  },
  watch: {
    "searchFilterModels.price.min"(val) {
      if (
        val.length === 0 ||
        val < this.searchFilterModelDefaults.price.min ||
        val > this.searchFilterModels.price.max
      ) {
        this.searchFilterModels.price.min = this.searchFilterModelDefaults.price.min;
      }
    },
    "searchFilterModels.price.max"(val) {
      if (
        val.length === 0 ||
        val > this.searchFilterModelDefaults.price.max ||
        val < this.searchFilterModels.price.min
      ) {
        this.searchFilterModels.price.max = this.searchFilterModelDefaults.price.max;
      }
    },
    "searchFilterModels.year.min"(val) {
      if (
        val.length === 0 ||
        val < this.searchFilterModelDefaults.year.min ||
        val > this.searchFilterModels.year.max
      ) {
        this.searchFilterModels.year.min = this.searchFilterModelDefaults.year.min;
      }
    },
    "searchFilterModels.year.max"(val) {
      if (
        val.length === 0 ||
        val > this.searchFilterModelDefaults.year.max ||
        val < this.searchFilterModels.year.min
      ) {
        this.searchFilterModels.year.max = this.searchFilterModelDefaults.year.max;
      }
    },
    "searchFilterModels.mileage.min"(val) {
      if (
        val.length === 0 ||
        val < this.searchFilterModelDefaults.mileage.min ||
        val > this.searchFilterModels.mileage.max
      ) {
        this.searchFilterModels.mileage.min = this.searchFilterModelDefaults.mileage.min;
      }
    },
    "searchFilterModels.mileage.max"(val) {
      if (
        val.length === 0 ||
        val > this.searchFilterModelDefaults.mileage.max ||
        val < this.searchFilterModels.mileage.min
      ) {
        this.searchFilterModels.mileage.max = this.searchFilterModelDefaults.mileage.max;
      }
    }
  },
  mounted() {},
  methods: {
    show() {
      this.$refs[this.modalRef].show();
    },
    hide() {
      this.$refs[this.modalRef].hide();
    },
    filterSearchResults() {
      this.$store.state.search.filter.models = JSON.parse(
        JSON.stringify(this.searchFilterModels)
      );
      this.$store
        .dispatch("search/CHECK_FILTER_MODELS_UPDATED")
        .then(updated => {
          if (updated) {
            this.$store.commit("search/RESET_KEYWORD");
            this.$store.commit("search/RESET_PAGINATION");
            this.$store.commit("search/SET_FILTER_MODELS_ACTIVE");
            this.$store.commit("search/SET_LOADING");
          }
          this.hide();
        });
    },
    resetFilters() {
      this.$store.commit("search/RESET_KEYWORD");
      this.$store.commit("search/RESET_PAGINATION");
      this.$store.commit("search/RESET_FILTERS");
      this.$store.commit("search/SET_LOADING");
      this.hide();
    },
    performSearchFilterOptionsRefresh(currentFilterField) {
      this.refreshingFilterOptions = currentFilterField;

      let filters = [
        {
          mileage: {
            gte: this.searchFilterModels["mileage"].min,
            lte: this.searchFilterModels["mileage"].max
          }
        },
        {
          price: {
            gte: this.searchFilterModels["price"].min,
            lte: this.searchFilterModels["price"].max
          }
        },
        {
          year: {
            gte: this.searchFilterModels["year"].min,
            lte: this.searchFilterModels["year"].max
          }
        }
      ];

      let orFilters = [];
      let orFilterKeys = ["make_id", "model_id"];
      Object.keys(this.searchFilterModels).forEach(filterModelKey => {
        if (["mileage", "price", "year"].includes(filterModelKey)) return;

        let filterInArray = this.searchFilterModels[filterModelKey];

        if (filterInArray.length > 0) {
          let filterKey = this.convertCamelToSnake(filterModelKey);
          if (orFilterKeys.includes(filterKey)) {
            orFilters.push({ [filterKey]: { in: filterInArray } });
          } else {
            filters.push({ [filterKey]: { in: filterInArray } });
          }
        }
      });

      if (orFilters.length > 0) {
        filters.push({ or: orFilters });
      }

      this.$store
        .dispatch("search/POPULATE_FILTER_OPTIONS", {
          filterParams: filters,
          includeLocationParam: true
        })
        .finally(() => {
          this.refreshingFilterOptions = "";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.auto__srp__filters-mobile {
  background: color(background);
  overflow-y: auto;
  max-height: calc(100% - 194px);

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
